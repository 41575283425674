/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Después de formatear mi partición de windows y reinstalar como no se borro el grub del inicio, para reinstalarlo necesitamos una LiveCD, yo he utilizado la del Kubuntu, pero podéis usar cualquier otra acordándoos de que tenéis que ejecutar los comandos como root."), "\n", React.createElement(_components.p, null, "Una vez dentro de la Live abrimos una consola."), "\n", React.createElement(_components.p, null, "Para saber nuestro cual es la partición de en que tenemos Linux y el disco con el que iniciamos:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">sudo fdisk -l</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Uno de ellos sera el que tenga la el Linux, la otra tendra Windows. Nos interesa la que tiene Linux que puede ser hda1, hda2,hdb1,hdb2… para los discos ATA o sda1,sda2… para los SATA. A mí Linux me confunde los discos y se piensa que son SATA aunque son ATA por eso la partición es la sda2."), "\n", React.createElement(_components.p, null, "A continuación creamos una carpeta donde montar la partición de Linux."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">mkdir /home/ubuntu/litox</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Montamos esta partición. Si vuestro sistema de archivos no fuera ext3 pues el que tengáis."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">sudo mount -t ext3 /dev/hda2 /home/ubuntu/litox</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Para que tengamos los hd y sd en la carpeta /dev de nuestra partición tenemos que usar este comando."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">mount -o bind /dev /home/ubuntu/litox/dev</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Lo siguiente será cambiar root al de nuestra partición Linux."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">sudo chroot /home/ubuntu/litox</code>"
    }
  })), "\n", React.createElement(_components.p, null, "A partir de ahora estamos en nuestra partición Linux y cualquier cosa que hagamos quedará permanentemente en esta partición. Por ultimo solo nos queda reinstalar el grub en el disco por eso no se pone sda2 que es la partición sino sda que es todo el disco."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">sudo grub-install /dev/sda</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Ahora solo tenemos que reiniciar y tendremos el grub igual que antes."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
